<template>
  <v-container fluid>
    <TheTitle :text="title" :icon="pageIcon" />

    <v-card class="pa-5">
      <v-form v-model="valid" ref="form1">
        <v-row class="mt-1">
          <v-col md="2" sm="12" cols="12">
            <b>Código</b>
            <v-text-field v-model="object.id" outlined tile dense hide-details class="my-2" disabled></v-text-field>
          </v-col>
          <v-col md="8" sm="12" cols="12">
            <b>Nome *</b>
            <v-text-field v-model="object.username" outlined tile dense hide-details class="my-2" :rules="[requiredRule, spaceRule]" id="tf_name"></v-text-field>
          </v-col>
          <v-col md="2" sm="12" cols="12">
            <b>Nascimento</b>
            <v-text-field v-model="object.birthday" outlined tile dense hide-details class="my-2" v-mask="'##/##/####'" placeholder="__/__/____"></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col md="3" sm="12" cols="12">
            <b>CPF</b>
            <v-text-field v-model="object.cpf" outlined tile dense hide-details class="my-2" v-mask="'###.###.###-##'" placeholder="___.___.___-__"></v-text-field>
          </v-col>
          <v-col md="3" sm="12" cols="12">
            <b>Telefone</b>
            <v-text-field v-model="object.phone" outlined tile dense hide-details class="my-2" v-mask="['(##) ####-####', '(##) #####-####']" placeholder="(__) _____ - ____"></v-text-field>
          </v-col>
          <v-col md="6" sm="12" cols="12">
            <b>E-mail</b>
            <v-text-field v-model="object.email" outlined tile dense hide-details class="my-2" disabled></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <b>Observações</b>
            <v-textarea v-model="object.observation" outlined tile dense hide-details class="my-2" rows="3" auto-grow></v-textarea>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="d-flex mb-6">
            <v-btn color="primary" dark v-on:click="resolveForm()">
              <v-icon small class="mr-2">mdi-content-save</v-icon> Salvar
            </v-btn>
            <a href="#" class="pa-2" v-on:click="updateAccessData()"> Quero alterar minha senha.</a>
            <span class="ml-auto caption">* Campos obrigatórios</span>
          </v-col>
        </v-row>
      </v-form>
    </v-card>

    <TheDialog v-model="dialog" :title="titleUpdateAccessData" :icon="iconUpdateAccessData">
      <v-card class="pa-5" flat>
        <!--
        <v-row>
          <v-col>
            Senha atual *
            <v-text-field v-model="object.password" outlined tile dense hide-details class="my-2" :rules="requiredRule"
              :append-icon="showPasswordData ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showPasswordData ? 'text' : 'password'"
              v-on:click:append="showPasswordData = !showPasswordData"
            ></v-text-field>
          </v-col>
        </v-row>
        -->
        <v-row>
          <v-col>
            Nova Senha *
            <v-text-field v-model="object.password" outlined tile dense hide-details class="my-2"
              :rules="requiredRule"
              :append-icon="showPasswordData ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showPasswordData ? 'text' : 'password'"
              @click:append="showPasswordData = !showPasswordData"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            Confirme a nova Senha *
            <v-text-field v-model="confirmPassword" outlined tile dense hide-details class="my-2"
              :rules="requiredRule"
              :append-icon="showPasswordData ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showPasswordData ? 'text' : 'password'"
              v-on:click:append="showPasswordData = !showPasswordData"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <template>
              <v-row class="pa-5">
                <v-col class="d-flex mb-6">
                  <v-btn color="primary" dark v-on:click="resolvePasswordUpdate()">
                    <v-icon small class="mr-2">mdi-content-save</v-icon>
                    Salvar
                  </v-btn>
                  <span class="ml-auto caption">* Campos obrigatórios</span>
                </v-col>
              </v-row>
            </template>
          </v-col>
        </v-row>
      </v-card>
    </TheDialog>
  </v-container>
</template>

<script>
import axios from 'axios'
import { baseApiUrl, showError } from '@/global'
import TheTitle from "@/components/TheTitle"
import TheDialog from "@/components/TheDialog"
import { mapState } from "vuex"
import { mask } from "vue-the-mask"

export default {
  name: "Perfil",
  directives: { mask },

  components: {
    TheTitle,
    TheDialog
  },

  data: () => ({
    baseRoute: "user",
    title: "Perfil do Usuário",
    titleUpdateAccessData: "Alterar senha",
    iconUpdateAccessData: "mdi-lock-reset",
    pageIcon: "mdi-home-account",
    // requiredRule: [(v) => !!v || "O Campo é necessário"],
    object: {},
    dialog: false,
    tabUpdateAccessData: null,
    showPasswordData: false,
    valid:false,
    confirmPassword: '',
  }),

  computed: {
    ...mapState(["user"]),
  },

  methods: {
    loadData() {
      this.$store.dispatch('setLoadingInfo', true)
      const url = `${baseApiUrl}/${this.baseRoute}/${this.user.id}`
      axios
        .get(url)
        .then((res) => {
          this.$store.dispatch('setLoadingInfo', false)
          this.object = res.data
        })
        .catch(showError)
    },

    resolveForm() {
      if (!this.valid) {
        this.$store.dispatch("setErrorInfo", {
          open: true,
          text: "Os campos não foram preenchidos corretamente. Por favor, verifique.",
          button: "Ok!",
        });

        document.getElementById("tf_name").focus()

        return
      }

      this.update()
    },

    resolvePasswordUpdate() {
      if (!this.object.password) {
        this.$store.dispatch("setErrorInfo", {
          open: true,
          text: "Informe a nova senha.",
          button: "Ok!",
        })

        return
      }

      else if (this.object.password !== this.confirmPassword) {
        this.$store.dispatch("setErrorInfo", {
          open: true,
          text: "As senhas informadas não conferem.",
          button: "Ok!",
        })

        return
      }

      else {
        this.updatePassword()

        this.$store.dispatch("setErrorInfo", {
          open: true,
          text: "Senha atualizada com sucesso !",
          button: "Feito !",
        })
      }
    },

    update() {
      this.error = false
      const id = this.user.id
      const url = `${baseApiUrl}/${this.baseRoute}/${id}`
      const objects = this.object
      delete objects.id
      delete objects.createdAt
      delete objects.updatedAt
      delete objects.deletedAt

      delete objects.email
      delete objects.password
      axios
        .put(url, objects)
        .then(() => this.loadData())
        .catch(showError)
        .finally(() => {
          this.dialog = false
        })
    },

    updatePassword() {
      this.error = false
      const id = this.user.id
      const url = `${baseApiUrl}/${this.baseRoute}/${id}`
      const objects = this.object
      delete objects.id
      delete objects.createdAt
      delete objects.updatedAt
      delete objects.deletedAt

      delete objects.email
      axios
        .put(url, objects)
        .then(() => this.loadData())
        .catch(showError)
        .finally(() => {
          this.dialog = false
        })
    },

    updateAccessData() {
      this.dialog = true
      this.object.password = ''
    },
  },

  mounted() {
    this.loadData()
  },
};
</script>

<style>
a { text-decoration: none; }
</style>
